import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import {
  Nav,
  Modal,
  SelectPicker,
  Container,
  Button,
  Message,
  Tag,
} from "rsuite";
import { EraIcon } from "../../styles/Icon.js";
//import { getUserInfo, getUserRoles } from "../../azure/MSGraphService.js";
import { appRoleMap } from "../../azure/authConfig.js";
//import { getActiveRole, setActiveRole } from "./CookieManager.js";
import { msGraphRequest } from "../../azure/authConfig.js";

import { useMsal } from "@azure/msal-react";
//import { clearCookies } from "./CookieManager.js";

export const RolesModal = ({
  open,
  onClose,
  userRoles,
  userRolesCompany,
  activeRole,
  setRole,
}) => {
  const [roleModal, setRoleModal] = useState();
  const [representedIMModal, setRepresentedIMModal] = useState();
  const [roleList, setRoleList] = useState([]);
  const [imList, setIMList] = useState([]);
  const [ready, setReady] = useState(false);

  //console.log(activeRole);
  //console.log(userRoles);
  //console.log(userRolesCompany);

  const roleNames = {
    nre: {
      label: "NRE - National Registration Entity",
      value: "nre",
    },
    im: { label: "IM - Infrastructure Manager", value: "im" },
    ru: { label: "RU - Railway Undertaking", value: "ru" },
  };

  const renderMenuItem = (label, item) => {
    return (
      <>
        <b>{label}</b>
        {item.country && <i style={{ marginLeft: "10px" }}>{item.country}</i>}
      </>
    );
  };

  useEffect(() => {
    setRoleModal(activeRole?.role);
    setRepresentedIMModal(activeRole?.company);
  }, [activeRole]);

  useEffect(() => {
    if (roleModal === "nre" && representedIMModal !== undefined) {
      setReady(true);
    } else {
      setReady(false);
    }

    if (roleModal === "ru" || roleModal === "im") {
      setReady(true);
    }
  }, [roleModal, representedIMModal]);

  useEffect(() => {
    let newRoleList = [];
    let newIMList = [];

    //console.log(userRoles);

    if (userRoles !== undefined) {
      for (let role of Object.keys(userRoles)) {
        if (userRoles[role] === true) {
          if (userRolesCompany?.nre?.country?.name !== undefined) {
            newRoleList.push({
              country: userRolesCompany?.nre?.country?.name,
              ...roleNames[role],
            });
          } else {
            newRoleList.push(roleNames[role]);
          }
        }
      }
    }

    if (userRolesCompany?.im !== undefined) {
      for (let im of userRolesCompany?.im) {
        //console.log(im);

        newIMList.push({
          label: im?.name,
          country: im.country?.name,
          value: im?.id,
        });
      }
    }

    setRoleList(newRoleList);
    setIMList(newIMList);
  }, [userRoles]);

  const onSetRole = (item) => {
    setRoleModal(item);
    //console.log(item);
  };

  const onSetIm = (item) => {
    setRepresentedIMModal(item);
    //console.log(item);
  };

  const onApplyChanges = () => {
    // For IM case, for the moment, also set the company to the one from SRM
    if (
      roleModal === "im" &&
      representedIMModal === null &&
      imList.length !== 0
    ) {
      setRole(roleModal, imList[0]);
    } else {
      setRole(roleModal, representedIMModal);
    }

    onClose();
  };

  return (
    <Modal size={"sm"} open={open} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Select user role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ marginBottom: "10px" }}>
          <Message showIcon type="info" style={{ marginBottom: "15px" }}>
            Select the user role you want to use in the RINF Apps.
          </Message>
          <SelectPicker
            placeholder={"Select user role"}
            data={roleList}
            value={roleModal}
            block
            renderMenuItem={renderMenuItem}
            onSelect={onSetRole}
            style={{ marginBottom: "10px" }}
          />

          {roleModal == "nre" && (
            <SelectPicker
              placeholder={"Select represented IM"}
              data={imList}
              value={representedIMModal}
              block
              renderMenuItem={renderMenuItem}
              onSelect={onSetIm}
              style={{ marginBottom: "10px" }}
            />
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Cancel
        </Button>
        <Button
          onClick={onApplyChanges}
          appearance="primary"
          color={"green"}
          disabled={!ready}
        >
          Change role
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(msGraphRequest).catch((e) => {
      //console.log(e);
    });
  };

  return <Nav.Item onClick={() => handleLogin()}>Sign in</Nav.Item>;
};

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .then(() => {});
  };

  return (
    <Nav.Item
      onClick={() => handleLogout()}
      icon={<EraIcon faName="right-from-bracket" />}
    >
      Sign out
    </Nav.Item>
  );
};

export const UserMenu = ({
  userInfo,
  userRoles,
  userRolesCompany,
  isAuthenticated,
  activeRole,
  setRole,
}) => {
  const [openRoleModal, setOpenRoleModal] = useState(false);

  useEffect(() => {
    //console.log(isAuthenticated, activeRole);
    if (isAuthenticated && activeRole?.role === undefined) {
      setOpenRoleModal(true);
    }
  }, [isAuthenticated, activeRole]);

  //console.log(isAuthenticated);

  const getUserString = () => {
    if (isAuthenticated) {
      if (activeRole?.role === "nre") {
        return (
          <>
            {userRolesCompany?.nre?.name} <b>&nbsp;/&nbsp;</b>
            {
              userRolesCompany?.im?.find((im) => im.id === activeRole?.company)
                .name
            }
          </>
        );
      }
      if (activeRole?.role === "im" || activeRole?.role === "ru") {
        return <>{userRolesCompany?.im?.[0].name}</>;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      {isAuthenticated && (
        <RolesModal
          open={openRoleModal}
          onClose={() => {
            setOpenRoleModal(false);
          }}
          userRolesCompany={userRolesCompany}
          userRoles={userRoles}
          activeRole={activeRole}
          setRole={setRole}
        />
      )}

      <Nav.Menu
        active={isAuthenticated === true}
        title={getUserString()}
        icon={<EraIcon faName="user" />}
      >
        {isAuthenticated && (
          <>
            {activeRole?.role === "nre" && (
              <>
                <Nav.Item
                  disabled
                  active
                  icon={<EraIcon faName="earth-europe" />}
                >
                  {"NRE: " + userRolesCompany?.nre?.name}
                </Nav.Item>

                {activeRole?.company !== null && (
                  <Nav.Item disabled active icon={<EraIcon faName="train" />}>
                    {"as IM: " +
                      userRolesCompany?.im?.find(
                        (im) => im.id === activeRole?.company
                      ).name}
                  </Nav.Item>
                )}
              </>
            )}

            {activeRole?.role === "im" && (
              <Nav.Item disabled active icon={<EraIcon faName="train" />}>
                {"IM: " + userRolesCompany?.im?.[0].name}
              </Nav.Item>
            )}

            {activeRole?.role === "ru" && (
              <Nav.Item disabled active icon={<EraIcon faName="train" />}>
                {"RU: " + userRolesCompany?.im?.[0].name}
              </Nav.Item>
            )}

            <Nav.Item
              disabled
              style={{
                padding: "0px",
                height: "5px",
                borderBottom: "1px dotted #c5c6c7",
              }}
            >
              <br></br>
            </Nav.Item>

            <Nav.Item disabled icon={<EraIcon faName="user" />}>
              {userInfo?.displayName}
            </Nav.Item>
            <Nav.Item disabled>{userInfo?.mail}</Nav.Item>
            {userInfo?.userRoles?.active && (
              <Nav.Item disabled>{userInfo?.userRoles?.active}</Nav.Item>
            )}
            <Nav.Item
              disabled
              style={{
                padding: "0px",
                height: "5px",
                borderBottom: "1px dotted #c5c6c7",
              }}
            >
              <br></br>
            </Nav.Item>
            <Nav.Item
              icon={<EraIcon faName="repeat" />}
              onClick={() => {
                setOpenRoleModal(true);
              }}
            >
              Change role
            </Nav.Item>
          </>
        )}

        {isAuthenticated ? (
          <SignOutButton />
        ) : (
          <>
            <SignInButton />
            <Nav.Item as={"a"} href="https://srm-portal.powerappsportals.com/">
              Reset Password
            </Nav.Item>
          </>
        )}
      </Nav.Menu>
    </>
  );
};
