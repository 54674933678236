import styled from "styled-components";
import mnIconPath from "../img/blue-dot.png";
import markerPath from "../img/mapbox-marker-icon-20px-blue.png";

// Operational point icons
const marker = new Image(10, 24);
marker.src = markerPath;

export { marker };

export const mapStyle = {
  //width: "75vw",
  width: "calc(100vw - 730px)",
  height: "calc(100vh - 150px)",
};

// Operational point icons
const mnIcon = new Image(10, 10);
mnIcon.src = mnIconPath;

export { mnIcon };

export const routeStyle = {
  "line-color": "rgba(0,0,0,0.7)",
  "line-width": 2,
  //"line-dasharray": [2, 2],
};

export const ERALogo = styled.img`
  width: 100%;
  height: 100%;
  z-index: 9000;
`;

export const MainImg = styled.img`
  width: 100%;
  margin: auto;
  height: 100%;
`;

export const eraLogoWrapper = {
  float: "left",
  paddingRight: "10px",
};

export const infoButton = {
  verticalAlign: 0,
  float: "right",
  cursor: "pointer",
  color: "#ffffa8",
};

export const stickyMenu = {
  position: "sticky",
  top: 0,
  zIndex: 2,
  background: "#f7f7fa",
};

export const tileFrameStyle = {
  "fill-color": "#6F788A",
  "fill-opacity": 0.3,
};

export const StyledPopup = styled.div`
  background: white;
  color: #3f618c;
  font-weight: 400;
  font-size: 14px;
  font-family: monospace;
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  max-width: 500px;
`;

export const LoadingGIF = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 75px;
  right: 0px;
  z-index: 9000;
`;

export const sideBar = {
  flexDirection: "column",
  flexBasis: 730,
  overflowY: "auto",
  height: "100%",
};

export const sidebarHeader = {
  padding: 18,
  fontSize: 22,
  height: 70,
  background: "#004494",
  color: " #fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const inputStyle = {
  fontSize: "22px",
  marginTop: "10px",
};

export const selectStyle = {
  width: "100%",
};

export const stepStyle = {
  padding: "5px",
  width: "200px",
  display: "inline-table",
  verticalAlign: "top",
};

export const stepItemStyle = {
  color: "green",
};

export const stepStyleStart = {
  color: "green",
  width: "200px",
  display: "inline-table",
  verticalAlign: "top",
};

export const stepStyleEnd = {
  color: "red",
  width: "200px",
  display: "inline-table",
  verticalAlign: "top",
};

export const panelStyle = (color) => {
  return {
    border: "solid",
    borderColor: color,
    overflowX: "auto",
    marginBottom: "10px",
  };
};

export const tableHeaderStyle = {
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
};

export const cellStyle = {
  borderLeft: "1px solid black",
  borderRight: "1px solid black",
  textAlign: "center",
};

export const cellStyleStatus = {
  borderTop: "1px solid black",
  borderRight: "1px solid black",
  textAlign: "center",
};

export const tableStatus = {
  border: "2px solid grey",
  width: "100%",
};

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const RoutesPermalinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
